/* You can add global styles to this file, and also import other style files */

/* Animate.css */
@import "elate/css/animate.css"; 

/* Icomoon Icon Fonts*/
@import "elate/css/icomoon.css"; 

/* Simple Line Icons */
@import "elate/css/simple-line-icons.css"; 

/* Magnific Popup */
@import "elate/css/magnific-popup.css"; 
/* Bootstrap */
//@import "elate/css/bootstrap.css"; ???
//*	
//	Default Theme Style 
//	You can change the style.css (default color purple) to one of these styles
//	
//	1. pink.css
//	2. blue.css
//	3. turquoise.css
//	4. orange.css
//	5. lightblue.css
//	6. brown.css
//	7. green.css
//

//@import "elate/css/style.css"; ??

//Override elate header for indato
$font-primary: 'Source Sans Pro', Arial, sans-serif;

// Overrides
$grid-gutter-width: 40px !default; 
$border-radius-base:  4px !default;
$padding-base-vertical: 14px !default;

$brand-primary: #0996a0 !default;
$brand-secondary: #fff !default; 



$brand-white: #fff;
$brand-black: #000;
$brand-darker: #444;
$brand-gray: #ccc;
$brand-lighter: #e9e9e9;
$brand-body-color: #818892;
$brand-selection-color: #f9f6f0;
$brand-overlay-color: #3b3d40;
$brand-bg-color: $brand-white;

$input-border-focus:  $brand-primary !default;
$form-group-margin-bottom:       30px !default;

// Import 
//@import 'elate/sass/bootstrap/mixins';??
@import 'elate/sass/bootstrap/variables';
// Mixin
@mixin transition($transition) {
    -moz-transition:    $transition ease;
    -o-transition:      $transition ease;
    -webkit-transition: $transition ease;
    -ms-transition: 	$transition ease;
    transition:         $transition ease;
}
@mixin inline-block() {
	display:-moz-inline-stack;
	display:inline-block;
	zoom:1;
	*display:inline;
}
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

#indato-header {
        // position: relative;
        position: absolute;
        z-index: 99;
        width: 100vw;
        opacity: 1;
        top: 0;
        /*margin-top: 40px;*/
        background-color: $brand-white;


        @media screen and (max-width: $screen-sm) {
		margin-top: 0;
		background: $brand-white;
		-webkit-box-shadow: 0 0 9px 0 rgba(0,0,0,.1);
		-moz-box-shadow: 0 0 9px 0 rgba(0,0,0,.1);
		-ms-box-shadow: 0 0 9px 0 rgba(0,0,0,.1);
		box-shadow: 0 0 9px 0 rgba(0,0,0,.1);
		.navbar-brand {
			color: $brand-primary!important;
		}
		#navbar {
			li {
				a {
					color: rgba(0,0,0,.5)!important;
					@include transition(.3s);
					&:hover {
						color: $brand-primary!important;
					}
					span {
						&:before {
							background: transparent!important;
						}
					}
				}
				&.active {
					a {
						background: transparent;
						background: none;
						color: $brand-primary!important;
						span {
							&:before {
								visibility: visible;
								-webkit-transform: scaleX(1);
								transform: scaleX(1);
							}
						}
					}
				}
			}
		}
	}
	// padding-top: 15px;
	.navbar {
		padding-bottom: 0;
		margin-bottom: 0;
	}
	#navbar {
		li {
			a {
				font-family: $font-primary;
				color: rgba(0,0,0,.5);
				position: relative;
				// text-transform: uppercase;
				font-size: 19px;
				font-weight: 300;
				// @include transition(.5s);
				span {
					position: relative;
					display: block;
					padding-bottom: 2px;
					&:before {
						content: "";
						position: absolute;
						width: 100%;
						height: 2px;
						bottom: 0;
						left: 0;
						background-color: rgba(255,255,255,.5);
						visibility: hidden;
						-webkit-transform: scaleX(0);
						-moz-transform: scaleX(0);
						-ms-transform: scaleX(0);
						-o-transform: scaleX(0);
						transform: scaleX(0);
						-webkit-transition: all 0.3s ease-in-out 0s;
						-moz-transition: all 0.3s ease-in-out 0s;
						-ms-transition: all 0.3s ease-in-out 0s;
						-o-transition: all 0.3s ease-in-out 0s;
						transition: all 0.3s ease-in-out 0s;
					}
				}
				&:hover {
					// color: $brand-primary;
					color: $brand-primary;
					// color: rgba(255,255,255,1);
					span {
						&:before {
							visibility: visible;
							-webkit-transform: scaleX(1);
							-moz-transform: scaleX(1);
							-ms-transform: scaleX(1);
							-o-transform: scaleX(1);
							transform: scaleX(1);
						}
					}
				}

			}
			&.active {
				a {
					background: transparent;
					background: none;
					color: $brand-primary;
					span {
						&:before {
							visibility: visible;
							-webkit-transform: scaleX(1);
							transform: scaleX(1);
						}
					}
				}
			}
		}
        }
        .navbar-brand {
            float: left;
            display: block;
            font-size: 30px;
            font-weight: 700;
            padding: 0;
            color: $brand-white;
            img {
                height: 30px;
                margin-top: 13px;
            }
        }
        @mixin navbar-fixed-top {
                position: fixed!important;
                background: $brand-white;
                -webkit-box-shadow: 0 0 9px 0 rgba(0,0,0,.1);
                -moz-box-shadow: 0 0 9px 0 rgba(0,0,0,.1);
                -ms-box-shadow: 0 0 9px 0 rgba(0,0,0,.1);
                box-shadow: 0 0 9px 0 rgba(0,0,0,.1);
                margin-top: 0px;
                top: 0;
                .navbar-brand {
                    color: $brand-primary;
                    img {
                        height: 30px;
                        margin-top: 13px;
                    }
                }
                #navbar {
                        li {
                                a {
                                        color: rgba(0,0,0,.5);
                                        @include transition(.3s);
                                        &:hover {
                                                color: $brand-primary;
                                        }
                                }
                                &.active {
                                        a {
                                                background: transparent;
                                                background: none;
                                                color: $brand-primary;
                                                span {
                                                        &:before {
                                                                visibility: visible;
                                                                -webkit-transform: scaleX(1);
                                                                transform: scaleX(1);
                                                                background-color: $brand-primary;
                                                        }
                                                }
                                        }
                                }
                        }
                }
        }
        &.navbar-fixed-top {@include navbar-fixed-top()}

/*        @media not screen and (min-width: $screen-sm) {
            .navbar-brand {
                color: $brand-primary;
                img {
                    height: 30px;
                    margin-top: 13px;
                }
            }            
            @include navbar-fixed-top();
            .navbar-header{
                position: relative;
                margin: 0;
            }
            .navbar {
                min-height: initial;
            }
            &.slideInDown {
                animation-name: none;
            }
        }       */
    	.navbar-default {
		border: transparent;
		background: transparent;
		@include border-radius(0px);
		@media screen and (max-width: $screen-sm) {
			margin-top: 0px;
			padding-right: 0px;
			padding-left: 0px;
		}
		.brand-slogan {
			margin: 28px 0 0 15px;
			float: left;
			letter-spacing: 2px;
			color: #adadad;
			em {
				color: $brand-primary;
				font-style: normal;
			}
		}
	}
	a {
		@include transition(.0s);
	}
}

#indato-intro {
  position: relative;
  bottom: 0;
  margin-top: -350px;
  z-index: 10;
  padding-bottom: 7em;
}
@media screen and (max-width: 768px) {
  #indato-intro {
    padding-bottom: 3em;
  }
}
#indato-intro > div {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
}
#indato-intro .fh5co-block {
  width: 33.33%;
  /*float: left;*/
  text-align: center;
  font-size: 16px;
  min-height: 300px;
  /*vertical-align: middle;*/
  padding: 40px;
  background: #fff;
  -webkit-box-shadow: 7px 14px 42px 3px rgba(163, 174, 184, 0.4);
  -moz-box-shadow: 7px 14px 42px 3px rgba(163, 174, 184, 0.4);
  -ms-box-shadow: 7px 14px 42px 3px rgba(163, 174, 184, 0.4);
  -o-box-shadow: 7px 14px 42px 3px rgba(163, 174, 184, 0.4);
  box-shadow: 7px 14px 42px 3px rgba(163, 174, 184, 0.4);
  z-index: 7;
  flex-grow: 1;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
}
@media screen and (max-width: 600px) {
  #indato-intro .fh5co-block {
    width: 100%;
  }
}
#indato-intro .fh5co-block > .overlay-darker {
  z-index: 8;
  opacity: .5;
  background: #474747;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
#indato-intro .fh5co-block > .overlay {
  background: #fff;
  opacity: 1;
  z-index: 9;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
#indato-intro .fh5co-block > .fh5co-text {
  position: relative;
  z-index: 10;
}
#indato-intro .fh5co-block > .fh5co-text .fh5co-intro-icon {
  font-size: 50px;
  color: #0996a0;
  margin-bottom: 30px;
  display: block;
}
#indato-intro .fh5co-block h2 {
  font-size: 24px;
  font-weight: 400;
}
#indato-intro .fh5co-block:hover > .overlay, #fh5co-intro .fh5co-block:focus > .overlay {
  opacity: 0;
  z-index: 9;
}
#indato-intro .fh5co-block:hover > .fh5co-text, #fh5co-intro .fh5co-block:focus > .fh5co-text {
  color: #fff;
}
#indato-intro .fh5co-block:hover > .fh5co-text .fh5co-intro-icon, #fh5co-intro .fh5co-block:focus > .fh5co-text .fh5co-intro-icon {
  color: #fff;
}
#indato-intro .fh5co-block:hover h2, #fh5co-intro .fh5co-block:focus h2 {
  color: #fff;
}
#indato-intro .fh5co-block:hover .btn, #fh5co-intro .fh5co-block:focus .btn {
  border: 2px solid #fff !important;
  background: transparent;
}
#indato-intro .fh5co-block:hover .btn:hover, #fh5co-intro .fh5co-block:focus .btn:hover {
  background: #0996a0 !important;
  border: 2px solid #0996a0 !important;
}



// Burger Menu
.indato-nav-toggle {
  width: 25px;
  height: 25px;
  cursor: pointer;
  text-decoration: none;
  &.active i {
		&::before, &::after {
			background: $brand-primary;
		}
  }
  &:hover, &:focus, &:active {
  	outline: none;
  	border-bottom: none!important;
  }
  i {
  	position: relative;
	  @include inline-block;
	  width: 25px;
	  height: 3px;
	  color: $brand-primary;
	  font:bold 14px/.4 Helvetica;
	  text-transform: uppercase;
	  text-indent:-55px;
	  background: $brand-primary;
	  transition: all .2s ease-out;
		 &::before, &::after {
	  	  content:'';
		  width: 25px;
		  height: 3px;
		  background: $brand-primary;
		  position: absolute;
		  left:0;
		  @include transition(.2s);
	  }
  }
}

.indato-nav-toggle i::before {
  top: -7px;
}
.indato-nav-toggle i::after {
  bottom: -7px;
}
.indato-nav-toggle:hover i::before {
  top: -7px;
}
.indato-nav-toggle:hover i::after {
  bottom: -7px;
}
.indato-nav-toggle.active i {
	background: transparent;
}
.indato-nav-toggle.active i::before {
  top:0;
  -webkit-transform: rotateZ(45deg);
     -moz-transform: rotateZ(45deg);
      -ms-transform: rotateZ(45deg);
       -o-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
}
.indato-nav-toggle.active i::after {
  bottom:0;
  -webkit-transform: rotateZ(-45deg);
     -moz-transform: rotateZ(-45deg);
      -ms-transform: rotateZ(-45deg);
       -o-transform: rotateZ(-45deg);
          transform: rotateZ(-45deg);
}
.indato-nav-toggle {
  position: absolute;
  top: 2px;
  right: 0px;
  z-index: 21;
  padding: 6px 0 0 0;
  display: block;
  margin: 0 auto;
  display: none;
  height: 44px;
  width: 25px;
  border-bottom: none!important;
  @media screen and (max-width: $screen-sm) {
  	display: block;
  }
}

// Menu bar Animate
.indato-animated {
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.indato-no-margin-bottom { 
    margin-bottom: 0px; 
}

.indato-no-padding-bottom { 
    padding-bottom: 0px; 
}

.indato-intro-margin-top {
    margin-top: -200px;
}

.indato-intro-p-height {
    min-height: 220px;
}

#indato-fh5co-home {
  background-color: transparent;
  background-image: url(assets/images/home_v2.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: 50px;
  background-attachment: fixed;
  position: relative;
  width: 100%;
  color: #fff;
  overflow: hidden;
}
#indato-fh5co-home .gradient {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  opacity: .9;
  -webkit-backface-visibility: hidden;
  background-color: #0996a0;
  /* IE9, iOS 3.2+ */
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMTAwJSIgeTI9IjEwMCUiPjxzdG9wIHN0b3AtY29sb3I9IiMzZjk1ZWEiIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwIi8+PHN0b3Agc3RvcC1jb2xvcj0iIzUyZDNhYSIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjEiLz48L2xpbmVhckdyYWRpZW50PjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjdnNnZykiIC8+PC9zdmc+);
  background-image: -webkit-gradient(linear, 0% 0%, 100% 100%, color-stop(0, #0996a0), color-stop(1, #fff));
  /* Android 2.3 */
  background-image: -webkit-repeating-linear-gradient(top left, #0996a0 0%, #fff 100%);
  /* IE10+ */
  background-image: repeating-linear-gradient(to bottom right, #0996a0 0%, #fff 100%);
  background-image: -ms-repeating-linear-gradient(top left, #0996a0 0%, #fff 100%);
}

#indato-fh5co-home, #indato-fh5co-home .text-wrap {
  height: 900px;
}
#indato-fh5co-home .text-wrap {
  display: table;
  width: 100%;
  position: relative;
  z-index: 4;
}
#indato-fh5co-home .text-inner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
#indato-fh5co-home .text-inner a {
  color: white;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
#indato-fh5co-home .text-inner a:hover, #indato-fh5co-home .text-inner a:active, #indato-fh5co-home .text-inner a:focus {
  text-decoration: none;
  color: white;
  border-bottom: 1px dashed white;
}
#indato-fh5co-home .text-inner h1 {
  font-size: 50px;
  color: white;
  margin: 0 0 20px 0;
}
#indato-fh5co-home .text-inner h2 {
  font-size: 28px;
  line-height: 38px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
}

.indato-section-heading {
  float: left;
  width: 100%;
  padding-bottom: 50px;
  margin-bottom: 50px;
  clear: both;
}
.indato-section-heading h2 {
  margin: 0 0 30px 0;
  font-size: 50px;
  font-weight: 300;
  color: #444;
  text-transform: uppercase;
  position: relative;
  display: block;
  padding-bottom: 20px;
  line-height: 1.5;
}
.indato-section-heading h2.left-border:after {
  content: "";
  position: absolute;
  display: block;
  width: 80px;
  height: 2px;
  background: #0996a0;
  left: 0%;
  margin-left: 0px;
  bottom: 0;
}
.indato-section-heading h2:after {
  content: "";
  position: absolute;
  display: block;
  width: 80px;
  height: 2px;
  background: #0996a0;
  left: 50%;
  margin-left: -40px;
  bottom: 0;
}
@media screen and (max-width: 768px) {
  .indato-section-heading h2 {
    font-size: 30px;
  }
}
.indato-section-heading h3 {
  font-weight: 300;
  line-height: 1.5;
  color: #929292;
}
@media screen and (max-width: 768px) {
  .indato-section-heading h3 {
    font-size: 24px !important;
    line-height: 34px;
  }
}

a {
  color: #0996a0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
a:hover, a:active, a:focus {
  color: #0996a0;
  outline: none;
}

.indato-slant {
	position: absolute;
	transform: rotate(-1.7deg);
	-ms-transform: rotate(-1.7deg);
	-webkit-transform: rotate(-1.7deg);
	-o-transform: rotate(-1.7deg);
	-moz-transform: rotate(-1.7deg);
	background-color: #fff;
	content: "";
	z-index: 3;
	display: inline-block;
	height: 100px;
	bottom: 0;
	margin-bottom: -50px;
	padding: 0;
	width: 101%;
}

.inherit-height-parent {
    display: table;
}
.inherit-height {
    display: table-cell;
}

@media only screen and (max-width: 600px) {
  .inherit-height {
    display: block;
    width: 100%;
  }
}
.social.social-circle a {
  background: $brand-primary;
}

#indato-fh5co-news {
  overflow: hidden;
  position: relative;
  color: rgba(255, 255, 255, 0.8);
  background-color: #0996a0;
  /* IE9, iOS 3.2+ */
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMTAwJSIgeTI9IjEwMCUiPjxzdG9wIHN0b3AtY29sb3I9IiMzZjk1ZWEiIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwIi8+PHN0b3Agc3RvcC1jb2xvcj0iIzUyZDNhYSIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjEiLz48L2xpbmVhckdyYWRpZW50PjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjdnNnZykiIC8+PC9zdmc+);
  background-image: -webkit-gradient(linear, 0% 0%, 100% 100%, color-stop(0, #0996a0), color-stop(1, #E6E6E6));
  /* Android 2.3 */
  background-image: -webkit-repeating-linear-gradient(top left, #0996a0 0%, #E6E6E6 100%);
  /* IE10+ */
  background-image: repeating-linear-gradient(to bottom right, #0996a0 0%, #E6E6E6 100%);
  background-image: -ms-repeating-linear-gradient(top left, #0996a0 0%, #E6E6E6 100%);
}

#indato-fh5co-news:before, #indato-fh5co-news:after {
  position: absolute;
  transform: rotate(-1.7deg) translatex(-1px);
  -ms-transform: rotate(-1.7deg) translatex(-1px);
  -webkit-transform: rotate(-1.7deg) translatex(-1px);
  -o-transform: rotate(-1.7deg) translatex(-1px);
  -moz-transform: rotate(-1.7deg) translatex(-1px);
  background-color: #fff;
  content: "";
  z-index: 3;
  display: inline-block;
  height: 70px;
  padding: 0;
  width: 101%;
}

#indato-fh5co-news:before {
  top: 0;
  margin-top: -35px;
}

#indato-fh5co-news:after {
  bottom: 0;
  margin-bottom: -35px;
}

#indato-fh5co-news {
    padding: 4em 0;
    @media screen and (max-width: $screen-sm) {
            padding: 2em 0;
    }
}

#indato-fh5co-news .indato-section-heading h2 {
  color: #fff;
}
#indato-fh5co-news .indato-section-heading h2:after {
  background: rgba(255, 255, 255, 0.3);
}
#indato-fh5co-news .indato-section-heading h3 {
  color: rgba(255, 255, 255, 0.8);
}

#indato-fh5co-news .indato-section-heading {
  padding-bottom: 0px;
    @media screen and (max-width: $screen-sm) {
        padding-top: 2em;
    }
}

