/*!
 * Bootstrap v3.3.5 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
//@import "bootstrap/variables";

//indato GmbH - override icon-path
@import "indato_variables";
@import "elate/sass/bootstrap/mixins";

// Reset and dependencies
@import "elate/sass/bootstrap/normalize";
@import "elate/sass/bootstrap/print";
@import "elate/sass/bootstrap/glyphicons";

// Core CSS
@import "elate/sass/bootstrap/scaffolding";
@import "elate/sass/bootstrap/type";
@import "elate/sass/bootstrap/code";
@import "elate/sass/bootstrap/grid";
@import "elate/sass/bootstrap/tables";
@import "elate/sass/bootstrap/forms";
@import "elate/sass/bootstrap/buttons";

// Components
@import "elate/sass/bootstrap/component-animations";
@import "elate/sass/bootstrap/dropdowns";
@import "elate/sass/bootstrap/button-groups";
@import "elate/sass/bootstrap/input-groups";
@import "elate/sass/bootstrap/navs";
@import "elate/sass/bootstrap/navbar";
@import "elate/sass/bootstrap/breadcrumbs";
@import "elate/sass/bootstrap/pagination";
@import "elate/sass/bootstrap/pager";
@import "elate/sass/bootstrap/labels";
@import "elate/sass/bootstrap/badges";
@import "elate/sass/bootstrap/jumbotron";
@import "elate/sass/bootstrap/thumbnails";
@import "elate/sass/bootstrap/alerts";
@import "elate/sass/bootstrap/progress-bars";
@import "elate/sass/bootstrap/media";
@import "elate/sass/bootstrap/list-group";
@import "elate/sass/bootstrap/panels";
@import "elate/sass/bootstrap/responsive-embed";
@import "elate/sass/bootstrap/wells";
@import "elate/sass/bootstrap/close";

// Components w/ JavaScript
@import "elate/sass/bootstrap/modals";
@import "elate/sass/bootstrap/tooltip";
@import "elate/sass/bootstrap/popovers";
@import "elate/sass/bootstrap/carousel";

// Utility classes
@import "elate/sass/bootstrap/utilities";
@import "elate/sass/bootstrap/responsive-utilities";
